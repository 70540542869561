import Vue from 'vue'
import PesquisaClientes from '@/components/PesquisaClientes.vue'
import api from '@/modules/api'

var pesquisaCliente = function(clienteID) {
  if (!clienteID) {
    return new Promise((resolve, reject) => {
        const pesquisaClientes = new Vue(PesquisaClientes);

        pesquisaClientes.$once('modal-pesquisa-cliente-resultado', value => {
          pesquisaClientes.$destroy();
          pesquisaClientes.$el.remove();

          if (!value) {
            reject();
            return;
          }

          resolve(value);
        });

        pesquisaClientes.$mount();
        document.body.appendChild(pesquisaClientes.$el);
      });
  }

  return new Promise((resolve, reject) => {
    const r = api.get(`/customers/${clienteID}`);

    r.then(res => {
      resolve(res.data);
    }).catch((error) => {
      reject(error);
    });
  });
}

export default pesquisaCliente
