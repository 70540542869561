<template>
  <div class="pesquisa-clientes">
    <div style="z-index: 1050" aria-hidden="true" class="modal-backdrop fade show"></div>
    <div role="dialog" tabindex="-1" class="modal fade show d-block" aria-modal="true" aria-labelledby="modalFindCustomer">
      <div role="document" class="modal-dialog modal-xl modal-fullscreen-margin">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <font-awesome-icon :icon="['fas', 'users']" fixed-width/>
              Localizar cliente
            </h5>
            <button type="button" v-on:click="fechaModal()" class="btn-close" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div v-if="(!preSelecionado)" class="row">
              <div class="col-12">
                <div class="input-group">
                  <input
                      v-model="search.q"
                      v-on:keyup.enter="handlePesquisa(true)"
                      type="text"
                      class="form-control"
                      placeholder="Pesquisar"
                      aria-label="Pesquisar"
                      aria-describedby="button-addon2">
                  <button v-on:click="handlePesquisa(true)" class="btn btn btn-outline-primary" type="button">
                    <font-awesome-icon :icon="['fas', 'search']" fixed-width/>
                  </button>
                </div>
              </div>
              <div class="col-12 mt-2">
                <table class="table table-bordered table-sm table-striped table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th>Razão social</th>
                      <th class="d-none d-lg-table-cell">Endereço</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="cliente in listaClientes"
                        :key="cliente.id"
                        v-on:click="preSelecionaCliente(cliente)"
                        class="my-sm cursor-pointer">
                      <td scope="row">
                        <i class="fa text-secondary mr-1 fa-user"></i>
                        {{cliente.name}}
                        <font-awesome-icon v-if="(cliente.obs)" :icon="['far', 'comment']" fixed-width class="me-1"/>
                      </td>
                      <td class="d-none d-lg-table-cell">-</td>
                    </tr>
                  </tbody>
                </table>
                <div class="form-group">
                  <Paginador v-model="paginador" @change-paginacao="onChangePaginacao"/>
                </div>
              </div>
            </div>

            <div v-if="(preSelecionado)" class="row small ng-star-inserted">
              <div class="col-12">
                <button @click="(preSelecionado = undefined)" class="btn btn-outline-primary">
                  <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width class="me-1"/>Voltar
                </button>
              </div>

              <div class="col-12 mt-2">
                <p class="text-info mb-0"><strong style="font-size: 16px;" class="border-bottom border-info">Dados do cliente selecionado</strong></p>
                <span class="">
                  <strong style="font-size: 14px;">{{preSelecionado.name}}</strong>
                </span>
              </div>
              <div class="col-md-auto mr-4">
                <i class="fa fa-address-card fa-fw"></i>
                <span v-if="!!preSelecionado.addresses && preSelecionado.addresses.length > 0" class="ms-1">{{shared.customer.addressReadable(preSelecionado.addresses[0])}}</span>
                <span v-else>-</span>
              </div>
              <div class="col-auto mr-4">
                <i class="fa fa-phone fa-fw"></i> {{shared.customer.phonesReadable(preSelecionado.phones)}}
              </div>
              <div class="col-auto mr-4 d-none">
                <i class="fa fa-dollar-sign fa-fw"></i> Limite disp.: R$ 0,00
              </div>
              <div v-if="(preSelecionado.obs)" class="col-12">
                <div class="alert alert-warning" role="alert">
                  <font-awesome-icon :icon="['far', 'comment']" fixed-width class="me-1"/> Observação:
                  <p class="mt-2 mb-0"><em>{{ preSelecionado.obs }}</em></p>
                </div>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button v-on:click="fechaModal()" class="btn btn-outline-secondary" type="button">
              <font-awesome-icon :icon="['fas', 'times']" fixed-width/>
              Cancelar
            </button>
            <button v-on:click="selecionaCliente" v-bind:disabled="!podeSelecionarCliente" class="btn btn-success" type="button">
              <font-awesome-icon :icon="['fas', 'check']" fixed-width/>
              Selecionar
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Paginador from '@/components/Paginador.vue'
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import clone from '@/modules/clone'
import shared from '../modules/shared'
import model from '@/model'

export default {
  name: 'PesquisaClientes',
  components: {
    Paginador,
  },
  data() {
    return {
      preSelecionado: undefined,
      /** @type {model.paginador} */
      paginador: clone(model.paginador),
      search: {
        q: '',
      },
      listaClientes: [],
    }
  },
  methods: {
    fechaModal() {
      this.$emit('modal-pesquisa-cliente-resultado', undefined);
    },

    handlePesquisa(resetPage) {
      const q = this.search.q;
      let params = this.paginador.requestParams;
      if (resetPage) {
        params = this.paginador.requestParamsFirst;
      }

      this.preSelecionado = undefined;
      loading(true);
      api.get(`/customers?${params}&orderBy[name]&q=${q}&with=Addresses`).then(res => {
        loading(false);
        this.listaClientes = res.data.items;
        this.paginador.pagina = res.data.page;
        this.paginador.totalItens = res.data.totalItems;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    onChangePaginacao() {
      this.handlePesquisa();
    },

    preSelecionaCliente(cliente) {
      this.preSelecionado = cliente;
    },

    selecionaCliente() {
      const clienteSelecionado = clone(this.preSelecionado);
      this.$emit('modal-pesquisa-cliente-resultado', clienteSelecionado);
    },
  },
  computed: {
    podeSelecionarCliente() {
      return (!!this.preSelecionado);
    },
    shared() {
      return shared;
    },
  },
  mounted() {
    this.handlePesquisa();
  }
}
</script>

<style>
.pesquisa-clientes .modal-body {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
}
</style>
