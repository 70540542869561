<template>
  <div class="assinaturas-isp">
    <h1 class="titulo-card fs-4">Clientes / Assinatura</h1>
    <div class="form-group card-principal">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-1">
          <button v-on:click="$router.go(-1)" class="btn btn-outline-primary">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width class="me-1"/>Voltar
          </button>
        </div>
      </div>

      <div v-if="(customer)" class="row">
        <div class="col-12 col-md-6 col-lg-4 mb-2">
          <label>Endereço</label>
          <select v-model="data.addressId" :disabled="(!customer.id)" class="form-select">
            <option :value="'00000000-0000-0000-0000-000000000000'">Não definido</option>
            <option v-for="address in customer.addresses" :key="address.id" :value="address.id">{{shared.customer.addressReadable(address)}}</option>
          </select>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-4 mb-2">
          <label>Banco</label>
          <select v-model="data.bankId" class="form-select">
            <option :value="undefined">Nenhum</option>
            <option
                v-for="b in banks"
                :key="b.id"
                :value="b.id"
                :class="{'d-none': (b.active == false && b.id !== data.bankId)}"
                :disabled="(!b.active)">
              {{b.description}}
            </option>
          </select>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-2">
          <label>Plano</label>
          <select v-model="data.planId" class="form-select">
            <option v-bind:value="undefined" disabled>Selecione</option>
            <option v-for="plano in plans" :key="plano.id" v-bind:value="plano.id" v-show="(plano.active || plano.id === data.planId)">{{plano.description}}</option>                </select>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-2">
          <label>Usuário</label>
          <div class="input-group">
            <input v-model="data.login"
                @keyup="setTyping"
                v-on:blur="exitTyping"
                v-on:keyup.enter="exitTyping"
                :disabled="checkingIfLoginExists()"
                type="text"
                class="form-control no-left-border">
            <span class="input-group-text ig-full-white">
              <font-awesome-icon v-if="waitingForloginInput()" :icon="['far', 'hourglass']" fixed-width class="text-muted"/>
              <font-awesome-icon v-if="checkingIfLoginExists()" :icon="['fas', 'spinner']" spin fixed-width class="text-muted"/>
              <font-awesome-icon v-if="canUseLogin()" :icon="['fas', 'check']" fixed-width class="text-success" />
              <font-awesome-icon v-if="cantUseLogin()" :icon="['fas', 'ban']" fixed-width class="text-danger"/>
            </span>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-2">
          <label>Senha</label>
          <input v-model="data.password" type="text" class="form-control">
        </div>

        <div class="col-12 col-sm-6 col-md-3 col-lg-4 mb-2">
          <label>Endereço IPv4</label>
          <input v-model="data.fixedIPv4Address" type="text" class="form-control" placeholder="Automático">
        </div>

        <div class="col-12 col-sm-6 col-md-3 col-lg-4 mb-2">
          <label>Data contratado</label>
          <input v-model="form.subscribedDate" type="date" class="form-control">
        </div>

        <div class="col-12 col-sm-6 col-md-3 col-lg-4 mb-2">
          <label>Dia de vencimento</label>
          <select v-model.number="data.dueDay" class="form-select">
            <option disabled :value="0">Selecione</option>
            <option :value="1">1</option>
            <option :value="2">2</option>
            <option :value="3">3</option>
            <option :value="4">4</option>
            <option :value="5">5</option>
            <option :value="6">6</option>
            <option :value="7">7</option>
            <option :value="8">8</option>
            <option :value="9">9</option>
            <option :value="10">10</option>
            <option :value="11">11</option>
            <option :value="12">12</option>
            <option :value="13">13</option>
            <option :value="14">14</option>
            <option :value="15">15</option>
            <option :value="16">16</option>
            <option :value="17">17</option>
            <option :value="18">18</option>
            <option :value="19">19</option>
            <option :value="20">20</option>
            <option :value="21">21</option>
            <option :value="22">22</option>
            <option :value="23">23</option>
            <option :value="24">24</option>
            <option :value="25">25</option>
            <option :value="26">26</option>
            <option :value="27">27</option>
          </select>
        </div>

        <div class="col-12 col-sm-6 col-md-3 col-lg-4 mb-2">
          <label>Dias para corte</label>
          <input v-model.number="data.dueDays" type="number" class="form-control">
        </div>

        <div class="col-12">
          <button type="button"
              v-if="(!subscriptionID)"
              v-on:click="handleModalAssinaturaISPCadastrar()"
              v-bind:disabled="!podeSalvarAssinaturaISP()"
              class="btn btn-success">
            <font-awesome-icon :icon="['fas', 'plus']" fixed-width class="me-1"/>
            Cadastrar
          </button>
          <button type="button"
              v-if="(subscriptionID)"
              v-on:click="handleModalAssinaturaISPSalvar()"
              v-bind:disabled="!podeSalvarAssinaturaISP()"
              class="btn btn-success">
            <font-awesome-icon :icon="['fas', 'check']" fixed-width class="me-1"/>
            Salvar
          </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import clone from '@/modules/clone'
import godate from '@/modules/godate'
import shared from '@/modules/shared'
import pesquisaCliente from '@/modules/pesquisa-cliente'

export default {
  name: 'EditSubscription',

  components: {
  },

  data() {
    return {
      customerID: undefined,
      subscriptionID: undefined,
      customer: undefined,
      addresses: [],
      subscriptionConfig: {
        dueDays: 0,
      },
      plans: [],
      banks: [],
      subscriptions: [],
      customers: [],
      data: {
        id: undefined,
        customerId: undefined,
        addressId: '00000000-0000-0000-0000-000000000000',
        planId: undefined,
        subscribedDate: '',
        login: '',
        password: '',
        fixedIPv4Address: '',
        status: 'active',
        dueDay: 0,
      },
      form: {
        subscribedDate: undefined,
      },
      loginCheck: {
        waiting: true,
        checking: false,
        valid: false,
      },
    }
  },

  methods: {
    async init() {
      this.customerID = this.$route.params.customerID;
      this.subscriptionID = this.$route.params.subscriptionID;
      this.data.dueDays = this.subscriptionConfig.dueDays;
      this.form.subscribedDate = shared.date.todayMysql();
      if (!this.customerID) {
        this.$router.go(-1);
        return;
      }

      await this.loadConfig();
      await this.getPlans();
      await this.getBanks();
      if (this.subscriptionID) {
        await this.getSubscription();
      }
      await this.getCustomer();
    },

    async getCustomer() {
      await api.get(`/customers/${this.customerID}`).then(res => {
        this.customer = res.data;
      }).catch(() => {
        this.$router.go(-1);
      });
    },

    async getSubscription() {
      await api.get(`/isp/subscriptions/${this.subscriptionID}`).then(res => {
        this.data = res.data;
        this.form.subscribedDate = shared.godate.toMysql(res.data.subscribedDate);
        this.loginCheck.waiting = false;
        this.loginCheck.valid = true;
      }).catch(() => {
        this.$router.go(-1);
      });
    },

    async loadConfig() {
      await api.get(`/configs/subscription`).then(res => {
        this.subscriptionConfig.dueDays = res.data.dueDays;
      }).catch(() => {
        this.$router.go(-1);
      });
    },

    async getPlans() {
      await api.get(`/isp/plans?paginate.cancel`).then(res => {
        this.plans = res.data.items;
      }).catch(() => {
        this.$router.go(-1);
      });
    },

    async getBanks() {
      await api.get(`/banks?paginate.cancel&orderBy[description]`).then(res => {
        this.banks = res.data.items;
      }).catch(() => {
        this.$router.go(-1);
      });
    },


    // loginCheck: {
    //     waiting: true,
    //     checking: false,
    //     valid: false,
    //   },
    setTyping() {
      this.loginCheck.waiting = true;
      this.loginCheck.checking = false;
    },
    exitTyping() {
      if (!this.data.login) {
        return;
      }

      this.loginCheck.waiting = false;
      this.loginCheck.checking = true;
      api.get(`/isp/subscriptions?login=${this.data.login}`).then(res => {
        this.loginCheck.checking = false;
        this.loginCheck.valid = false;

        if (res.data.items.length === 0) {
          this.loginCheck.valid = true;
        }
        if (res.data.items.length > 0 && res.data.items[0].id === this.subscriptionID) {
          this.loginCheck.valid = true;
        }
      }).catch(error => {
        this.setTyping();
        dialogo.erroHttp(error);
      });
    },
    waitingForloginInput() {
      return (this.loginCheck.waiting);
    },
    checkingIfLoginExists() {
      return (!this.loginCheck.waiting && this.loginCheck.checking);
    },
    canUseLogin() {
      return (!this.loginCheck.waiting && !this.loginCheck.checking && this.loginCheck.valid);
    },
    cantUseLogin() {
      return (!this.loginCheck.waiting && !this.loginCheck.checking && !this.loginCheck.valid);
    },




    resetModalAssinaturaISP() {
      this.customer = {addresses: []};
      this.data.id = undefined;
      this.data.customerId = undefined;
      this.data.addressId = undefined;
      this.data.planId = undefined;
      this.data.subscribedDate = undefined;
      this.data.dueDay = 0;
      this.data.login = '';
      this.data.password = '';
      this.data.fixedIPv4Address = '';
      this.data.status = 'active';

      this.data.dueDays = this.subscriptionConfig.dueDays;

      this.form.subscribedDate = shared.date.todayMysql();
    },

    abreModalCadastroAssinaturaISP() {
      this.resetModalAssinaturaISP();
      document.getElementById('btn-modal-edita-assinatura-isp-abre').click();
    },

    abreModalPesquisaClientes() {
      document.getElementById('btn-modal-edita-assinatura-isp-fecha').click();
      pesquisaCliente().then(res => {
        document.getElementById('btn-modal-edita-assinatura-isp-abre').click();
        this.customer = clone(res);
        this.data.customerId = res.id;
      }).catch(() => {
        document.getElementById('btn-modal-edita-assinatura-isp-abre').click();
      });
    },

    podeSalvarAssinaturaISP() {
      switch (true) {
        case (!this.data.planId):
        case (!this.data.dueDay):
        case (this.data.login.length < 3):
        case (this.data.password.length < 3):
        case (!this.form.subscribedDate):
        case (!this.data.dueDays):
        case (this.data.dueDays < 0):
        case (!this.canUseLogin()):
          return false;

        default:
          return true;
      }
    },

    handleModalAssinaturaISPCadastrar() {
      const sendData = clone(this.data);
      sendData.customerId = this.customerID;
      sendData.subscribedDate = godate.fromMysql(this.form.subscribedDate);

      loading(true);
      api.post('/isp/subscriptions', sendData).then(() => {
        loading(false);
        this.$router.go(-1);
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    handleModalAssinaturaISPSalvar() {
      const sendData = clone(this.data);
      sendData.customerId = this.customerID;
      sendData.subscribedDate = godate.fromMysql(this.form.subscribedDate);

      loading(true);
      api.put('/isp/subscriptions', sendData).then(() => {
        loading(false);
        this.$router.go(-1);
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    nomePlano(planoID) {
      const plano = this.plans.find(x => planoID === x.id);

      if (!plano) {
        return '';
      }

      return plano.description;
    },

    nomeCliente(clienteID) {
      const cliente = this.customers.find(x => clienteID === x.id);

      if (!cliente) {
        return '';
      }

      return cliente.name;
    },

    enderecoAssinatura(enderecoID) {
      const endereco = this.listaEnderecos.find(x => enderecoID === x.id);

      if (!endereco) {
        return '';
      }

      return shared.customer.addressReadable(endereco);
    },
  },

  computed: {
    shared() {
      return shared;
    },
  },

  created() {
    this.init();
  }
}
</script>

<style>
.ig-full-white{
  background-color: white;
}

.no-left-border{
  border-right: 0px;
}
</style>